import { faDiscord, faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paper from "components/Paper";
import { DISCORD_SERVER_URL } from "helpers/discord";
import StyledFooter from "./styles";

const Footer = () => {
  return (
    <Paper elevation={0} className="d-flex justify-content-center">
      <StyledFooter>
        <div className="footer-spacing d-none d-lg-flex">
          Sons of Tangra - Discord killbot
        </div>
      </StyledFooter>
    </Paper>
  );
};

export default Footer;
